import {supabase} from "../routes/api/supabase"
import {writable} from "svelte/store"

export const currentUser = writable(null)

supabase.auth.onAuthStateChange((event, session) => {
  switch (event) {
  case "SIGNED_OUT":
    currentUser.update(_old => null) // eslint-disable-line no-unused-vars
    break
  case "SIGNED_IN":
    currentUser.update(_old => session.user) // eslint-disable-line no-unused-vars
    break
  default:
    console.log("UNKNOWN AUTH STATE:", event)
  }
})
